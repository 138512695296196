import { useSession } from '@faststore/sdk'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'

import { RenderClient } from 'src/components/RenderClient'

interface ItemListElement {
  item: string
  name: string
  position: number
}
const getItemListElement = (
  elements: Array<{ item: string; name: string }>
): ItemListElement[] => {
  return elements?.map((e, index: number) => ({
    item: e?.item,
    name: e?.name,
    position: index + 1,
  }))
}

function Page() {

  const { locale } = useSession()

  const cmsData = {
    seo: {
      siteMetadataWithSlug: {
        slug: "/volantino-prezzi-ribassati",
        description: "Sfoglia le offerte Arcaplanet con il volantino dei Prezzi Ribassati. Abbiamo abbassato i prezzi di oltre 400 prodotti. Scopri le offerte del mese!",
        title: "Volantino Prezzi Ribassati",
        titleTemplate: "%s | Store Theme"
      }
    },
    config: {
      slugAndFilterConfig: {
        slug: "/volantino-prezzi-ribassati",
        pageTitle: "Volantino Prezzi Ribassati",
      }
    }
  }

  const site = {
    siteMetadata: {
        titleTemplate: "%s | Arcaplanet",
        title: "Cibo e accessori per cani, gatti e animali domestici",
        description: "Arcaplanet negozio online di cibo per cani, gatti e altri animali. Alimentazione, accessori, antiparassitari sempre in offerta per la felicità e la salute del tuo pet.",
        siteUrl: "https://www.arcaplanet.it"
    }
  }

  const filterTitle = cmsData?.seo?.siteMetadataWithSlug?.title
  const filterDescription = cmsData?.seo?.siteMetadataWithSlug?.description
  const seoSlug = cmsData?.seo?.siteMetadataWithSlug?.slug

  const title = site?.siteMetadata?.title ?? ''
  const canonical = `${site?.siteMetadata?.siteUrl}${seoSlug}`

  const breadcrumbName =
    cmsData?.config?.slugAndFilterConfig?.pageTitle ??
    filterTitle?.replaceAll('| Arcaplanet', '')

  const path = "/volantino-prezzi-ribassati";

  const itemListElements = getItemListElement([
    { item: path, name: breadcrumbName },
  ])

  return (
    <RenderClient>
      <GatsbySeo
        title={filterTitle ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={filterDescription ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: filterDescription ?? '',
        }}
      />

      <BreadcrumbJsonLd itemListElements={itemListElements ?? []} />

      <div style={{ position: "relative", paddingTop: "max(60%,326px)", height: 0, width:"100%" }}>
        <iframe allow="clipboard-write" 
                sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
                allowFullScreen
                style={{ position: "absolute", border: "none", width: "100%", height:"100%", left: 0, right: 0, top: 0, bottom:0 }}
                src="https://e.issuu.com/embed.html?d=arcaplanet_prezzi_ribassati_v.ot_2024&hideIssuuLogo=true&u=arcaplanet">
        </iframe>
      </div>
    </RenderClient>
  )
}

export default Page
